// import { WebService } from './web.service';
import { Session } from './../models/Session';
import { User } from './../models/User';
import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';

@Injectable({
	providedIn: 'root'
})
export class SessionService {
	private session: Session;

	private user: User;

	constructor(private storageService: StorageService
		// , private WebService: WebService
	) {
		this.session = null;
	}

	public Init(): Promise<Session> {
		return this.Read().then(result => {
			this.session = new Session(result);

			return this.session;
		});
	};

	public Read(): Promise<Session> {
		return this.storageService.Get(StorageService.KEYS.USER_SESSION).then(result => {
			return JSON.parse(result);
		},
			(error) => {
				console.warn("ReadSession : ", error);
			});
	}

	public Set(session: Session): Session {
		this.session = new Session(session);

		this.storageService.Set(StorageService.KEYS.USER_SESSION, JSON.stringify(this.session));

		return this.session;
	}

	public Get(): Session {
		return this.session;
	}

	/**
	 * Actualiza o objecto de sessão para os seus valores padrão. Será similar a um logout.
	 * O utilizador terá que iniciar um nova sessão.
	 */
	public Logout() {
		//REMOVE STORAGE PELO ID
		this.storageService.Remove(StorageService.KEYS.USER_SESSION);

		this.session = new Session();
	}
}
