import { environment } from './../../environments/environment';
// import { NavigatorService } from '../app/services/navigator.service';
import { SerializerBaseClass } from "./ASerializerBaseClass";


import { ResourceModel } from './ResourceModel';

import { ImagePhoto } from './ImagePhoto';

interface IAction {
	Description: string;
	Feature: string;
	Id: number;
	IdPermission: number;
	IdTarget: number;
	idSubMenu: number;
}

export class ItemMenuModel extends SerializerBaseClass {
	private static UNIQUE_ID: number = 0;

	/**
	 * Identificador unico do item.
	 */
	public Id: number;

	/**
	 * Index to item. Nos favoritos pode ser usado para indicar posição de ordenação.
	 */
	public Index: number;

	/**
	 * Indica se o menu é o menu default de navegação após login com sucesso.
	 */
	public Default: boolean;

	/**
	 * Nome/titulo do item. 
	 */
	public Name: string;

	/**
	 * Valor da propriedade resource. Deve ser usado em conjunto com o pipe/servico de translate.
	 */
	public ResourceName: string;

	/**
	 * Descrição do item.
	 */
	public Description: string;

	/**
	 * String que representa o nome/valor do component angular que deve ser instanciado quando a aplicação navega.
	 */
	public _IdComponent: string;

	/**
	 * Nome da class css do icon. O icon provem da font em css.
	 */
	public Icon: string;

	/**
	 * Helper para verificar se o item possui sub menus.
	 */
	public HasSubMenus: boolean;

	/**
	 * Identificador unico do item parent (pai do item).
	 * Util para pesquisar relação do item com outros elementos, se o item for encontrado isolado.
	 */
	public IdParent: number;

	/**
	 * Objecto ItemMenuModel do parent do menu.
	 * Só existe parent se o item actual for child de algum outro item.
	 */
	public Parent: ItemMenuModel;

	/**
	 * Identifica o tipo de navegação que deve ocorrer:
	 * 1 - Para navegar para uma nova root (nav.setRoot(item.Component));
	 * 2 - Para navegar em stack (nav.push(item.Component));
	 * 2 - Para modal/overlay (usar um controller de modal para invocar component);
	 */
	public IdNavigationType: number;

	/**
	 * O item pode ser renderizado, mas não deve ocorrer interação/navegação.
	 */
	public IsNotActive: boolean;

	/**
	 * Lista de objectos ItemMenuModel que representam os filhos do item.
	 * Quando existe uma lista de submenus, uma nova lista deve ser renderizada.
	 */
	public _SubMenus: Array<ItemMenuModel>;

	/**
	 * Lista de opcoes de resources com várias linguas.
	 */
	public _Resources: Array<ResourceModel>;

	/**
	 * Boolean de controlo para representação de itens em sistema coplase.
	 * Se o ShowChildren for true a UI deve manipular os elementos para ficarem visiveis.
	 */
	public _ShowSubMenus: boolean;

	/**
	 * Valor string do icon que está activo para toggle.
	 * Este valor é alterado automaticamente no setter da propriedade ShowSubMenus.
	 */
	public IconToggleShowSubMenus: string;

	/**
	 * permissões de acesso por utilizador
	 */
	public Actions: Array<IAction>;


	constructor(data?: any) {
		super();

		//SET DEFAULT VALUES TODOS OS MENUS COM SUBMENUS ESTAO FECHADOS
		this.ShowSubMenus = false;

		// debugger
		// EXTERNAL ACCESS
		// if (data && data.IdComponent
		// 	&& (data.IdComponent.includes('http') || data.IdComponent.includes('$'))
		// 	&& data.IdComponent.includes('=')
		// 	&& data.IdComponent.includes('?')) {

		// 	this.IdNavigationType = 1;

		// 	const url = data.IdComponent.split('?');

		// 	data.Default = url[1].split('=')[1] || null;
		// 	data.IdComponent = url[0];
		// 	data.SubMenus = [];

		// 	if (url[1].includes('$')) {
		// 		const key = url[1].split('=')[1].replace("$","");

		// 		data.Default = environment.ExternalAccessConfig[key] ? environment.ExternalAccessConfig[key] : data.Default;

		// 	}
		// }

		this.Serialize(data);

		// this.Id = ++ItemMenuModel.UNIQUE_ID;
		this.Actions = data && data.Actions ? data.Actions : [];


	}

	get Resources(): Array<ResourceModel> {
		return this._Resources;
	}

	set Resources(value: Array<ResourceModel>) {
		this._Resources = [];

		value.forEach(element => {
			this._Resources.push(new ResourceModel(element));
		});
	}

	get SubMenus(): Array<ItemMenuModel> {

		return this._SubMenus;
	}

	set SubMenus(value: Array<ItemMenuModel>) {
		this._SubMenus = [];

		this.HasSubMenus = value.length > 0;

		value.forEach(element => {
			let submenu = new ItemMenuModel(element);

			submenu.Parent = this;

			this._SubMenus.push(submenu);
		});
	}

	get ShowSubMenus(): boolean {
		return this._ShowSubMenus;
	}

	set ShowSubMenus(value: boolean) {
		this._ShowSubMenus = value;

		value ? this.IconToggleShowSubMenus = "chevron-up-outline" : this.IconToggleShowSubMenus = "chevron-down-outline";
	}

	get IdComponent(): string {
		return this._IdComponent;
	}

	set IdComponent(value: string) {
		this._IdComponent = value;
	}
}