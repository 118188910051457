export interface IKeyValuePair {
	Id?: number,
	IdType?: number,
	IdParent?: number,
	Name?: string,
	Value?: any,
	Title?: any,
	Filters?: any
	FilterCall?: number,
	Description?: string,
	Priority?: number
}

export class KeyValuePair implements IKeyValuePair {
	public Id?: number;

	public IdType?: number;

	public Name?: string;

	public Value?: any;

	public Title?: any;

	public Filters?: any;

	public FilterCall?: number;

	public Description?: string;

	public Priority?: number;

	constructor(data: Partial<KeyValuePair> = {}) {
		data = data || {};

		Object.assign(this, data);
	}
}