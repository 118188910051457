export interface IStructure {
	Id?: number,
	Description?: string,
	Title?: string,
	Resource?: string,
	Selected?: boolean,
	PcDesignation?: string,
	Icon?: string;
	Active?: boolean;
}

export class Structure implements IStructure {
	public Id: number;
	public Description: string;
	public Title: string;
	public PcDesignation: string;
	public Resource: string;
	public Icon: string;
	public Selected?: boolean;
	public Active?: boolean;


	constructor(data: Partial<Structure> = {}) {
		data = data || {};

		this.Id = data.Id || 0;

		this.Description = data.Description || "";

		this.Title = data.Title || "";

		this.Icon = data.Icon || "";

		this.Active = data.Active || false;
	}
}