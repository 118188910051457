import { ToastController, AlertController } from '@ionic/angular';
import { WebService } from './web.service';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { IonicSelectableComponent } from 'ionic-selectable';

@Injectable({
	providedIn: 'root'
})
export class UtilsService {
	constructor(private translateService: TranslateService, private WebService: WebService, private toastController: ToastController, private alertController: AlertController) {

	}

	/**
	 * Pede mais resources ao servidor
	 * @param language 
	 */
	public GetMoreResources(language: string): Promise<any> {
		return this.WebService.GetMoreResources(language).then(result => {
			const getTranslate = result;

			if (getTranslate) {
				this.AddResources(language, getTranslate);
			}

			return;
		});
	}

	/**
	 * Adiciona novos resources à base 
	 * @param language 
	 * @param data 
	 */
	public AddResources(language: string, data: any) {
		this.translateService.setTranslation(language, data, true);
	}

	/**
	 * Verifica a segurança da password e atribui uma pontuação.
	 * Valoriza a ausencia de repetições, verifica se possui letras minusculas, letras maiusculas caracteres especiais e digitos. 
	 * Uma "boa" password é presentada por uma password com mais de 50 pontos.
	 * @param pass string 
	 */
	public GetPasswordSecurityScore(pass: string) {
		var score: number = 0;

		if (!pass) return score;

		//pontuacao letras unicas ate 5 repeticoes
		var letters = new Object();

		for (let i = 0; i < pass.length; i++) {
			letters[pass[i]] = (letters[pass[i]] || 0) + 1;

			score += 5.0 / letters[pass[i]];
		}

		//pontucao adicional digitos, minusculas, maiusculas, chars especiais
		var variations =
		{
			digits: /\d/.test(pass),
			lower: /[a-z]/.test(pass),
			upper: /[A-Z]/.test(pass),
			nonWords: /\W/.test(pass),
		}

		let variationCount = 0;

		for (var check in variations) {
			variationCount += (variations[check] == true) ? 1 : 0;
		}

		score += (variationCount - 1) * 10;

		return (score > 100) ? 100 : score;
	}

	public TranslateResource(resource: string) {
		this.translateService.get(resource).subscribe(value => { resource = value; });

		return resource;
	}

	public async presentToast(message, durability: number = 2000, cssclass: string = '', position: any = 'middle') {
		const toast = await this.toastController.create(
			{
				message: this.translateService.instant(message),
				duration: durability,
				position: position,
				cssClass: cssclass
			});

		toast.present();
	};

	/**
	 * Creates alert to confirm an action.
	 * @returns true for confirm; false for cancel;
	 */
	public async confirmAction(): Promise<boolean> {

		const alert = await this.alertController.create({
			header: this.translateService.instant("INTENDS_TO_PERFORM_THE_OPERATION"),
			buttons: [
				{
					text: this.translateService.instant("CANCEL"),
					role: 'cancel',
					handler: () => { }
				},
				{
					text: this.translateService.instant("YES"),
					role: 'confirm',
					handler: () => { }
				}
			],
			cssClass: 'custom-alert'
		})

		alert.present();
		return await alert.onDidDismiss().then((data) => { return data.role === "confirm" });
	}

	//#region custom ionic selectable search handling
	SearchSelectableItems(event: { component: IonicSelectableComponent; text: string }, items:any[], itemToSearch:string) {
		let text = event.text.trim().toLowerCase();
		event.component.startSearch();
	
		event.component.items = this.filterCaseInsensitiveSelectableItems(items, text, itemToSearch);
		event.component.endSearch();
	}

	filterCaseInsensitiveSelectableItems(items:any[], text:string, itemToSearch){
	
		if (!text || text.length <= 0) return items;

		if (!items || !text || !itemToSearch || text.length <= 0) {
			return items;
		}
		
		const normalizedSearchTerm = this.normalizeText(text);

		return items.filter(item => {
			const normalizedValue = this.normalizeText(item[itemToSearch].toLowerCase());
			return normalizedValue.includes(normalizedSearchTerm.toLowerCase());
		});  

	}

	private normalizeText(text: string): string {
		return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
	}
	//#endregion
}
