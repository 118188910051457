export interface IContacts
{
	Telephone?:string,
	Email?:string
}

export class Contacts implements IContacts
{
	public Telephone:string;

	public Email:string;

	constructor(data: Partial<Contacts> = {})
	{
		data = data || {};
		
		this.Telephone = data.Telephone || "";

		this.Email = data.Email || "";
	}
}