export class ResultObjectModel {
	public Error: string;
	public Message: string;

	public Exception?: any;

	public Status: Number;

	public Object: any;

	/**
	* Objecto de Resultado do Webservice
	* @param data
	*/
	constructor(data: Partial<ResultObjectModel> = {}) {
		data = data || {};

		this.Error = data.Error || '';

		this.Exception = data.Exception || '';

		this.Status = (data.Status || data.Status == 0) ? data.Status : null;

		this.Object = data.Object || null;

		this.Message = data.Message || 'Erro ao realizar a operação.'

	}
}