export interface ISession
{
	AccessToken?: string;
	DateCreate?: string;
	Expires?: number;
	ExpiresDate?: string;
}

export class Session implements ISession
{
	/**
	 * Access Token de sessão.
	 */
	public AccessToken: string;

	/**
	 * Data de criação do token.
	 */
	public DateCreate: string;

	/**
	 * Tempo para expiração.
	 */
	public Expires: number;

	/**
	 * Data em que o token expira.
	 */
	public ExpiresDate: string;

	/**
	 * Objecto date da data de expiração.
	 */
	public ExpiresDateObject: Date;

	constructor(data: Partial<Session> = {})
	{
		data = data || {};

		this.AccessToken 	= data.AccessToken || "";
		this.DateCreate 	= data.DateCreate 	|| "";
		this.ExpiresDate 	= data.ExpiresDate 	|| "";
		this.Expires 		= data.Expires 		|| 0;

		//FOR DEBUG AND TEST
		// this.ExpiresDate = "2019-12-31T23:58:00.000Z";

		(this.ExpiresDate && this.ExpiresDate != "") ? this.ExpiresDateObject = new Date(this.ExpiresDate) : null;
	}

	public IsValid(): boolean 
	{
		if (!this.ExpiresDateObject) return false;

		//A DATA DO CLIENTE DEVE SER OBTIDA EM FUNCAO DO TIMEZONE ESPECIFICO UTC (UTC -> HORA SERVIDOR)
		//O LOCAL STRING É DEFINIDO EM "en-EN" PARA MANTER A ORDEM CORRECTA DE MES/DIA E PODER CONVERTER PARA OBJECTO new Date() AUTOMATICAMENTE
		//SE POR EXEMPLO DEFINIR "pt-PT" COMO LOCALE, VAI RETORNAR A DATA dd/mm/aaaa EM VEZ DE "mm/dd/aaaa".
		const clientDateTimeZone:string = new Date().toLocaleString("en-EN", { timeZone: "UTC" });

		//CRIA NOVA OBJECTO Date() COM DATA/HORA DO USER EM UTC
		//JÁ VAI CONTER O DIFERENCIAL DA DATA CALCULADO EM FUNCAO DA DATA EM UTC
		const clientDate:Date = new Date(clientDateTimeZone);

		//DUPLICA/COPIA OBJECTO Date() DA DATA DE EXPIRACAO DO SERVIDOR PARA NAO MANIPULAR DIRECTAMENTE O OBJECTO BASE
		const serverDate:Date = new Date(this.ExpiresDateObject.getTime());
		
		//SE A DATA DO SERVIDOR FOR SUPERIOR A DATA DO CLIENTE O TOKEN AINDA ESTÁ VALIDO!
		return serverDate.getTime() > clientDate.getTime();
	}
}