import { PAGES } from './../pages/Index';
import { Injectable } from '@angular/core';

@Injectable(
	{
		providedIn: 'root'
	})
export class RedirectService {
	private redirectURL: string;

	constructor() {

	}

	/**
	 * Guarda um URL para redirect a após login a partir do ecra login ou do initialize.
	 * @param url url parcial correspondente a um state de navegação
	 */
	public Set(url: string) {
		this.redirectURL = url;
	}

	/**
	 * Verifica se o path de redirect é valido.
	 * Se existir path valido retorna o path, caso contrario retorna null.
	 */
	public GetURLForRedirect(): string {
		console.log("redirectURL: " + this.redirectURL);

		if (this.redirectURL
			&& this.redirectURL != ""
			&& this.redirectURL.indexOf("/" + PAGES.INITIALIZE.path) === -1
			&& this.redirectURL.indexOf("/" + PAGES.DASHBOARD.path) === -1
			&& this.redirectURL.indexOf("/" + PAGES.LOGIN.path) === -1) {
			let copy: string = this.redirectURL.slice(0, this.redirectURL.length);

			this.redirectURL = null;

			console.log("redirect copy: " + copy);

			if (copy == "/") copy = null;

			return copy;
		}

		return null;
	}
}
