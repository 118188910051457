import { Directive, Output, EventEmitter } from '@angular/core';

@Directive({
	selector: '[custom-shortcuts]',
	host: {
		'(window:keyup)': 'onPressKey($event)'
	}
})

export class KeyboardShortcutsDirective {

	@Output('callback') Callback: EventEmitter<Array<any>> = new EventEmitter();

	constructor() { }

	ngOnInit() {

	}

	onPressKey(ev: any) {
		// console.log(ev, this.Callback);
		//&& (ev.shiftKey || ev.altKey || ev.ctrlKey) 
		if (ev && this.Callback) this.Callback.emit(ev);
	}
}
