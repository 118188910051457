import { Directive, ElementRef, HostListener, OnInit, Renderer2, AfterContentInit, Input } from '@angular/core';



@Directive({
	selector: '[customTooltip]'
})
export class TooltipDirective implements AfterContentInit {

	@Input() customTooltip: string;
	@Input() customTooltipIconMargin: string;


	private El: ElementRef;
	constructor(
		private el: ElementRef,
		private renderer: Renderer2) {
		this.El = el;

	}

	// @HostListener('mouseenter') onMouseEnter() {
	// 	this.highlight('yellow');

	// }

	// @HostListener('mouseleave') onMouseLeave() {
	// 	this.highlight(null);
	// }

	// private highlight(color: string) {
	// 	this.El.nativeElement.style.backgroundColor = color;

	// }


	ngAfterContentInit() {
		this.onLoadElement();
	}

	onLoadElement() {

		if (!this.customTooltip) return;

		// console.log(this.El.nativeElement);

		// this.El.nativeElement.setAttribute('style', 'pointer-events: auto !important;');


		// DELAY PARA O TRANSLATE || WORKAROUND
		const t = setTimeout(() => {
			clearTimeout(t);
			// this.El.nativeElement.innerHTML = this.El.nativeElement.innerHTML + '<ion-button style="--ripple-color:transparent;--background-hover: transparent;margin-top: -6px;--padding-start: 6px;--padding-end: 6px;" shape="round" fill="clear" title="' + this.customTooltip + '"><ion-icon slot="icon-only" name="information-circle-outline"></ion-icon></ion-button>';

			// this.El.nativeElement.innerHTML = this.El.nativeElement.innerHTML + '<span style="cursor:help;background: transparent;margin: 10px 6px;padding: 0px 10px;"><span style="width: 20px;position:absolute;height: 100%;z-index: 99; margin: 0px 0 0 -8px;" title="' + this.customTooltip + '"></span><ion-icon style="position: absolute; margin: 0px 0px 0px -9px;" name="information-circle-outline"></ion-icon></span>';
			this.El.nativeElement.innerHTML = `${this.El.nativeElement.innerHTML}<span class="custom-tooltip" style="cursor:help;background: transparent;margin: 10px 6px;padding: 0px 10px;"><span style="width: 20px;position:absolute;height: 100%;z-index: 99; margin: 0px 0 0 -8px;" title="${this.customTooltip}"></span><ion-icon style="position: absolute;margin:${this.customTooltipIconMargin ? this.customTooltipIconMargin : '3px 0px 0px -9px;'}" name="information-circle-outline"></ion-icon></span>`;

		}, 1);

	}

}
