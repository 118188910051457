import { StorageService } from './services/storage.service';
// import { ElectronUpdaterService } from './services/electron-updater.service';
// import { ElectronLoggerService } from './services/electron-logger.service';
import { RedirectService } from './services/redirect.service';
import { Router } from '@angular/router';
import { WebService } from './services/web.service';
import { SessionService } from './services/session.service';
import { Component } from '@angular/core';

import { Platform, MenuController, NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { UtilsService } from './services/utils.service';

@Component(
	{
		selector: 'app-root',
		templateUrl: 'app.component.html'
	})
export class AppComponent {
	constructor(
		private Platform: Platform,
		private TranslateService: TranslateService,
		private SessionService: SessionService,
		private MenuController: MenuController,
		private WebService: WebService,
		// private ElectronUpdaterService: ElectronUpdaterService,
		// private ElectronLoggerService: ElectronLoggerService,
		private UtilsService: UtilsService,
		private RedirectService: RedirectService,
		private NavController: NavController,
		private StorageService: StorageService,
		private Router: Router) {
		//DISABLE MENU ATE HAVER UMA SESSAO VALIDA
		this.MenuController.enable(false);

		//GUARDA PATH DE ENTRADA NA APLICACAO PARA FUTURO REDIRECT SE NECESSARIO
		this.RedirectService.Set(window.location.pathname + window.location.search);

		this.Platform.ready().then(async () => {
			//INIT DO WEBSERVICE
			this.WebService.Init();

			// //INIT LOGGER SERVICES
			// ElectronLoggerService.Init();

			// //INIT UPDATER ELECTRON SERVICE
			// ElectronUpdaterService.Init();

			await this.StorageService.Init();

			this.TranslateService.addLangs(['pt-pt']);

			this.TranslateService.setDefaultLang('pt-pt');

			this.TranslateService.use('pt-pt');

			this.getTranslations();

			const session = await this.SessionService.Init()
			// console.log("SessionService Init : ", session);

			//APOS OBTER A SESSAO ACTUAL ATRIBUI TOKEN NO WS
			if (session && session.AccessToken)
				this.WebService.SetAuthorization(session.AccessToken);

			this.NavController.navigateRoot("/", { animated: false });


		});
	}

	private getTranslations(lang?: string) {

		lang = lang ? lang : this.TranslateService.getDefaultLang();

		const l = lang.split("-")[0];

		return this.WebService.GetTranslations(l).then((obj: string) => {

			if (obj) {
				const o = JSON.parse(obj) || {};
				this.UtilsService.AddResources(lang, o);
			}
		})
	}
}
