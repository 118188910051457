import { AppUpdaterPageModule } from './../pages/app-updater/app-updater.module';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { HeaderComponent } from './header/header.component';
import { ContentComponent } from './content/content.component';
import { FooterComponent } from './footer/footer.component';
import { MenuDataService } from '../services/menu-data.service';

@NgModule({
	imports: [
		CommonModule,
		ReactiveFormsModule,
		IonicModule,
		TranslateModule.forChild(),
		RouterModule.forChild([])
	],
	declarations: [
		
		HeaderComponent,
		ContentComponent,
		FooterComponent
	],
	exports: [
		HeaderComponent,
		ContentComponent,
		FooterComponent
	],
	providers: [MenuDataService]
})
export class SidemenuComponentModule { }
