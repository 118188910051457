import { ItemMenuModel } from './ItemMenuModel';


interface IPermissionActionsTargetValues {
	Id: number;
	IdPermission: number;
	Description: string;
	Feature: string;
	IdTarget: number;
	idSubMenu: number;
}
export interface IPermissionActions {
	IdPermission: number;
	IdSubmenu: number;
	IdTarget: number;
	Target: string;
	TargetPermissionCount: number;
	TargetValue: number;
	TargetValues: Array<IPermissionActionsTargetValues>;
	UserType: number;
}

export class PermissionActions implements IPermissionActions {

	IdPermission: number;
	IdSubmenu: number;
	IdTarget: number;
	Target: string;
	TargetPermissionCount: number;
	TargetValue: number;
	TargetValues: Array<IPermissionActionsTargetValues>;
	UserType: number;

	constructor(data: Partial<IPermissionActions> = {}) {
		this.IdPermission = data.IdPermission || 0;
		this.IdSubmenu = data.IdSubmenu || 0;
		this.IdTarget = data.IdTarget || 0;
		this.Target = data.Target || null;
		this.TargetPermissionCount = data.TargetPermissionCount || 0;
		this.TargetValue = data.TargetValue || 0;
		this.TargetValues = data.TargetValues || [];
		this.UserType = data.UserType || 0;
	};
}

interface IPermission {
	Menus?: Array<ItemMenuModel>;
	Actions?: IPermissionActions;
}

export class Permission implements IPermission {
	Menus?: Array<ItemMenuModel>;
	Actions?: IPermissionActions;

	constructor(data: Partial<Permission> = {}) {
		this.Menus = data.Menus || new Array<ItemMenuModel>();
		this.Actions = data.Actions || new PermissionActions();

	}
}