import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';
import { AppUpdaterPage } from './app-updater.page';

import { TranslateModule } from '@ngx-translate/core';

@NgModule(
{
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        TranslateModule.forChild()
    ],
    declarations: [AppUpdaterPage]
})
export class AppUpdaterPageModule { }
