export const PAGES = {
	LOGIN: {
		path: 'login'
	},
	NOTFOUND: {
		path: 'error-page'
	},
	INITIALIZE: {
		path: 'initialize'
	},
	DASHBOARD: {
		path: 'dashboard'
	},
	BACKOFFICEUSERS: {
		path: 'backoffice-users',
		LIST:
		{
			path: 'backoffice-users/list'
		},
		DETAILS:
		{
			path: 'backoffice-users/list/details'
		},
		ADD:
		{
			path: 'backoffice-users/add'
		}
	},
	MILITANTUSERS: {
		path: 'militant-users',
		LIST:
		{
			path: 'militant-users/list'
		},
		DETAILS:
		{
			path: 'militant-users/list/details'
		},
		ADD:
		{
			path: 'militant-users/add'
		},
		PROCESS:
		{
			path: 'militant-users/process',
			DETAILS:
			{
				path: 'militant-users/process/details'
			}
		},
		UPDATE_CONTACTS:
		{
			path: 'militant-users/contact-updates',
			DETAILS:
			{
				path: 'militant-users/contact-updates/details'
			}
		}
	},
	PROCESS:
	{
		path: 'process',
		LIST:
		{
			path: 'process/list'
		},
		DETAILS:
		{
			path: 'process/list/details'
		}
	},
	PAYMENTS:
	{
		path: 'payments',
		PREVISION:
		{
			path: 'payments/prevision/list',
			LIST:
			{
				path: 'payments/prevision/list'
			},
			CREATE:
			{
				path: 'payments/prevision/create'
			},
			DETAILS:
			{
				path: 'payments/prevision/list/details'
			}
		},
		DISTRIBUTION:
		{
			path: 'payments/distribution/list',
			LIST:
			{
				path: 'payments/distribution/list'
			},
			CREATE:
			{
				path: 'payments/distribution/create'
			},
			DETAILS:
			{
				path: 'payments/distribution/list/details'
			}
		},
		MILITANT:
		{
			path: 'payments/militant/notification',
			NOTIFICATION:
			{
				path: 'payments/militant/notification'
			},
			ONDEMAND:
			{
				path: '/payments/militant/ondemand'
			}
		},
		REPORTS:
		{
			path: 'payments/reports'
		}

	},
	ELECTIONS: {
		path: '/elections',
		LIST:
		{
			path: '/elections/list',
			DETAILS:
			{
				path: '/elections/list/details'
			}
		},
		CREATE:
		{
			path: '/elections/create'
		},
		ASSINATURES:
		{
			path: '/elections/signatures',
			DETAILS:
			{
				path: '/elections/signatures/details'
			}
		},
	},
	CONGRESS: {
		path: '/congress',
		LIST:
		{
			path: '/congress/list',
			DETAILS: {
				path: '/congress/list/details'
			}
		},
		DELEGATES:
		{
			path: '/congress/delegates'
		},
		PARTICIPANTS:
		{
			path: '/congress/participants'
		},
		PLUS_OBSERVERS:
		{
			path: '/congress/plus-observers'
		},
		SEARCH:
		{
			path: '/congress/search'
		},
		STATISTICS:
		{
			path: '/congress/statistics'
		},
		PAYMENT_RETURNS:
		{
			path: '/congress/payment-returns'
		},
		EXTRA_PAYMENTS:
		{
			path: '/congress/extra-payments',
			DETAILS: {
				path: '/congress/extra-payments/details'
			}
		},
		EXPORT:
		{
			path: '/congress/export'
		},
		AUTHENTICATION:
		{
			path: '/congress/authentication'
		},
		CONFIGURATIONS:
		{
			path: '/congress/configurations',
			ORGAN_RESULTS:
			{
				path: '/congress/configurations/details'
			}
		},
		VOTES_DELEGATION:
		{
			path: '/congress/votes-delegation'
		},
	},
	CANDIDATURES:
	{
		path: 'candidatures/list',
		LIST:
		{
			path: 'candidatures/list'
		},
		SAVED:
		{
			path: 'candidatures/saved',
			FORM:
			{
				path: 'candidatures/saved/details'
			}
		},
		DETAILS:
		{
			path: 'candidatures/list/details'
		},
		VALIDATION:
		{
			path: 'candidatures/list/validation'
		},
		ARCHIVED:
		{
			path: 'candidatures/archived',
			DETAILS:
			{
				path: 'candidatures/archived/details'
			}
		}
	},
	CENMEMBERS: {
		path: 'members-cen',
		LIST:
		{
			path: 'members-cen/list'
		},
		DETAILS:
		{
			path: 'members-cen/list/details'
		},
		ADD:
		{
			path: 'members-cen/add'
		},
		CANDIDATURES:
		{
			path: 'members-cen/candidatures',
			LIST:
			{
				path: 'members-cen/candidatures/list'
			},
			DETAILS:
			{
				path: 'members-cen/candidatures/list/details'
			},
			VALIDATION:
			{
				path: 'members-cen/candidatures/list/validation'
			},
			SAVED:
			{
				path: 'members-cen/candidatures/saved',
				FORM:
				{
					path: 'members-cen/candidatures/saved/details'
				}
			},
		}
	},
	OTHERS: {
		path: 'generic',
		LISTING: {
			path: 'generic/listing'
		},
		EXTERNALACCESS: {
			path: 'generic/external'
		},
	},


}