import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'sidemenu-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

	public isOpen: boolean = true;

	constructor() { }

	ngOnInit() {
	}

	onCloseMenu() {
		this.isOpen = !this.isOpen;
		document.body.style.setProperty('--custom-menu-size', this.isOpen ? '18em' : '4.35em');
	}
}
