const pkg = require('../../package.json');

export const environment =
{
	production: true,

	PrivacyPolicyURL: "",
	ProjectConfig:
	{
		IdProject: 1,
		FolderName: "gestaoPS",
		Version: pkg.version,
		Bundle: "com.gestaoPS.gestao",
		Name: "gestaoPS",
		Author: "BoldApps",
		Description: "gestaoPS",
		Email: "info@gestaoPS.com",
		Site: "https://gestaoPS.com",
		CoordenadasGPS:
		{
			lat: "-9.1548847",
			lng: "38.7203552"
		}
	},
	UpdateServer:
	{
		EndPoint: "https://apps.ps.pt/releases.json"
	},
	APIConfig:
	{
		//EndPoint: "http://localhost:18457/PoliticService.svc/",
		// EndPoint: "http://localhost/PoliticServices/PoliticService.svc/",
		// EndPoint: "http://localhost/PoliticServices/PoliticService.svc/",
		EndPoint: "https://gestao.ps.boldapps.pt/service/PoliticService.svc/",
		// EndPoint: "https://teste.gestao.boldapps.pt/service/PoliticService.svc/",
		ClientId: "3041d9dc-fa09-4fbd-95b5-0aa5d35a81de-3c69a169-8a9b-43b8-9ebc-47b68c5a9760",
		ClientSecret: "48f3d738-0cd9-4859-801c-9404e7b88f9c-b996fa91-c98f-476c-bed6-820d4206cb8c"
	},
	ExternalAPIConfig:
	{
		GoogleMaps:
		{
			EndPoint: "http://maps.google.com/maps/api/js?v=3.34",
			ClientId: "AIzaSyBcAH1VGJrPofx8Wtk-4ODzTVoMpA6nU4k",
			ClientSecret: ""
		}
	},
	PushNotifications:
	{
		Id: 0,
		Config: {
			SenderId: "",
			Icon: "badgelogo",
			IconColor: "#FFFFFF",
			Key: "",
			Badge: true,
			Sound: true,
			Alert: true,
			ChannelName: "fsdea",
			fcmSandbox: true
		}
	},
	Credentials:
	{
		BackofficeUser:
		{
			Username: "",
			Password: ""
		}
	}
};
