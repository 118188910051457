import { PAGES } from './../pages/Index';
import { Session } from './../models/Session';
import { MenuDataService } from './../services/menu-data.service';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild, CanActivateFn, UrlTree } from '@angular/router';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';

import { MenuController, NavController } from '@ionic/angular';

import { SessionService } from '../services/session.service';

@Injectable({
	providedIn: 'root'
})
export class AuthenticationService {
	constructor(
		private NavController: NavController,
		private SessionService: SessionService,
		private MenuController: MenuController,
		private MenuDataService: MenuDataService) {

	}

	canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
		let session: Session = this.SessionService.Get();

		//session.HasValidSession = true;

		// console.log("AuthenticationGuard canActivate");
		// console.log("ActivatedRouteSnapshot next: ", next);
		// console.log("RouterStateSnapshot state: ", state);
		// console.log(" ");

		// debugger

		if (session && session.IsValid()) {
			//SE PODE NAVEGAR E O MENU ESTA FECHADO ABRE O MENU
			this.MenuController.enable(true);


			const url = state.url.split('?')[0];
			console.log(url);

			//VERIFICA A EXISTENCIA DE QUERY STRING NO ROUTER
			//SE EXISTIR idmenu NA QUERY STRING, VERIFICA SE EXISTE PARENT DO MENU EM NAVEGACAO
			//SE EXISTIR PARENT E O MENU ESTIVER FECHADO ABRE O MENU LATERAL
			// if (next.queryParams["idmenu"]) {
			// 	let menu = this.MenuDataService.GetParentMenu(next.queryParams["idmenu"]);
			// 	console.log("menu parent : ", menu);
			// 	if (menu == undefined) {
			// 		menu = this.MenuDataService.GetMenu(next.queryParams["idmenu"]);
			// 		if (menu == undefined) {
			// 			this.SessionService.Logout();
			// 			this.NavController.navigateRoot(PAGES.LOGIN.path, { animated: false });
			// 			return false;
			// 		}
			// 	}

			// 	if (menu && menu.ShowSubMenus == false) menu.ShowSubMenus = true;
			// }
			if (url && url != '/' + PAGES.NOTFOUND.path) {
				let menu = undefined;

				// para ter acesso directo aos detalhes é necessario ter a permissao com este nome [ACCESS_DETAILS]
				if (url.includes('/details')) {
					menu = this.MenuDataService.GetMenuByPath(url.split('/details')[0], 'ACCESS_DETAILS');
				}
				else if (url.includes('/validation')) {
					menu = this.MenuDataService.GetMenuByPath(url.split('/validation')[0], 'ACCESS_VALITATION');
				}
				else {
					menu = this.MenuDataService.GetMenuByPath(url);
				}

				if (menu == undefined) {
					// this.SessionService.Logout();
					this.NavController.navigateRoot(PAGES.NOTFOUND.path, { animated: false, queryParams: { id: 1 } });
					return false;

				}

				// ABRIR DROPDOWN PAI DO MENU SE TIVER FILHO
				const parent = this.MenuDataService.GetParentMenu(menu.Id);
				if (parent)
					parent.ShowSubMenus = true;

			}

			return true;
		}
		else {
			this.MenuController.enable(false);

			this.NavController.navigateRoot([PAGES.LOGIN.path], { animated: false });

			return false;
		}
	}

	// canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
	// 	return this.canActivate(next, state);
	// }
}

export const AuthenticationGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
	return inject(AuthenticationService).canActivate(next, state);
}

