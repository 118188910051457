import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';


export enum EnumStorageKeys {
	USER_PREFERENCES = '_userpreferences',
	USER_SESSION = '_usersession'
};

@Injectable(
	{
		providedIn: 'root'
	})
export class StorageService {
	/**
	 * chaves para guardar ou aceder aos dados
	 */
	public static KEYS = EnumStorageKeys;

	constructor(protected storage: Storage) { }


	/**
	 * Inicializa storage.
	 */
	async Init() {
		this.storage = await this.storage.create();
	}

	/**
	 * Guarda os dados referentes à key
	 * @param key 
	 * @param data 
	 */
	public async Set(key: EnumStorageKeys, data: any) {

		try {
			await this.storage?.set(key, JSON.stringify(data));

			return data;
		}
		catch (error) {
			return null;
		}
	}

	/**
	 * Retorna os dados guardados
	 * @param key 
	 */
	public async Get(key: EnumStorageKeys) {
		// return this.storage.get(key).then(result => {
		// 	// console.log("get storage key " + key + ":", result);
		// 	return JSON.parse(result);
		// })

		try {
			const value = await this.storage?.get(key);

			console.log("get storage key " + key + ":", value);

			return JSON.parse(value);
		}
		catch (error) {
			return null;
		}

	}

	/**
	 * Remove os dados guardados referentes à key 
	 * @param key 
	 */
	public async Remove(key: string) {
		try {
			await this.storage.remove(key);
			// console.log("remove storage key " + key);
		}
		catch (error) {
			return null;
		}
	}
}
