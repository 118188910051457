import { SerializerBaseClass } from './ASerializerBaseClass';

export class ResourceModel extends SerializerBaseClass
{     
      /**
       * Valor da resource que será mostrado na aplicação.
       */
      public Descricao:string;

      /**
       * Identificador da resource.
       */
      public Id:number;

      /**
       * Identificador do tipo de resource, id da lingua.
       */
      public IdTipo:number;

      /**
       * Resource a ser usada no templating como binding.
       */
      public Resource:string;

      public Valor:string;

      constructor(data?:any)
      {
            super();

            this.Serialize(data);
      }
}