import { PAGES } from './../../pages/Index';
import { Router, ActivatedRoute, UrlSegment } from '@angular/router';
import { Component, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { MenuController } from '@ionic/angular';

import { ItemMenuModel } from '../../models/ItemMenuModel';
import { MenuDataService } from './../../services/menu-data.service';

// import { filter, switchMap } from 'rxjs/operators';
// import { Subscription } from 'rxjs';


@Component(
	{
		selector: 'sidemenu-content',
		templateUrl: './content.component.html',
		styleUrls: ['./content.component.scss'],
	})
export class ContentComponent implements OnInit, OnChanges {
	public MenuData: Array<ItemMenuModel>;

	private selectedMenu: ItemMenuModel;

	public ComponentForRedirect:string;

	constructor(private MenuDataService: MenuDataService,
		private MenuController: MenuController,
		private ActivatedRoute: ActivatedRoute,
		private Router: Router) {

	}

	ngOnInit() {

		this.ComponentForRedirect = PAGES.OTHERS.EXTERNALACCESS.path;

		// this.MenuDataService.GetData().then(result => 
		// {
		// 	this.MenuData = result;
		// });

		this.MenuData = this.MenuDataService.Data;

		this.ActivatedRoute;
	}

	ngOnChanges(changes: SimpleChanges) {
		changes;
	}

	OnItemMenuClick(menu: ItemMenuModel) {

		//SE EXISTIR CHILDREN FAZ COLAPSE DOS CHILDREN DO MENU
		//NAO EXECUTA MAIS NENHUMA ACCAO DE ROUTING/NAVEGACAO
		if (menu.HasSubMenus) {
			if (this.selectedMenu)
				this.MenuData.filter(it => it.HasSubMenus).forEach(element => {
					// console.log(this.selectedMenu.Id, element.Id);
					if (element.ShowSubMenus && this.selectedMenu.Id != element.Id && (element.Id != menu.Id)) {
						element.ShowSubMenus = false;
					}
				});

			menu.ShowSubMenus = !menu.ShowSubMenus;
		}
		else {

			this.MenuData.forEach(element => {
				if (element.Id != menu.IdParent && element.IdParent === 0 && menu.IdParent > 0 && (this.selectedMenu && this.selectedMenu.Id != menu.IdParent)) {
					element.ShowSubMenus = false;
					// console.log(element.Name, element.IdParent, element.Id);
					// console.log(menu.Name, menu.IdParent, menu.Id);
				}

			});

			this.selectedMenu = this.MenuData.filter(it => it.Id === menu.IdParent)[0];

			//SE OCORREU NAVEGACAO, PODE FECHAR O MENU
			this.MenuController.close();
		}
	}

	GetParentSubMenuByURL(url: UrlSegment): ItemMenuModel {
		url.path;
		url.parameters;
		url.parameterMap;
		url.toString();

		let parent: ItemMenuModel = this.MenuData.find(result => result.IdComponent == url.path);

		return parent;
	}

	IsLinkActive(menu: ItemMenuModel): boolean {
		let url: string = menu.IdComponent;
		let queryParamsIndex = this.Router.url.indexOf('?');
		let baseUrl = queryParamsIndex === -1 ? this.Router.url : this.Router.url.slice(0, queryParamsIndex);

		let matchUrl: boolean = false;

		if (url.length > 1) {
			matchUrl = baseUrl.startsWith(url);
		}
		else {
			matchUrl = baseUrl === url;
		}

		//EXISTE MATCH E TEM PARENT, PESQUISA PARENT E EXPAND O MENU
		if (matchUrl && menu.Parent.Id != 0) {
			let parent: ItemMenuModel = this.MenuDataService.GetParentMenu(menu.Id);

			if (parent && parent.HasSubMenus && parent.ShowSubMenus == false) {
				parent.ShowSubMenus = true;
			}
		}

		return matchUrl;
	}
}
