import { WebService } from './web.service';
import { SessionService } from './session.service';
import { StorageService } from './storage.service';
import { Session } from './../models/Session';
import { Injectable } from '@angular/core';
import { User } from './../models/User';


export interface IUSERREGIONACCESS {
	REGIONAL: number,
	DISTRICT: number,
	COUNTY: number
}

export interface IUSERTYPES {
	ADMIN?: number,
	BACKOFFICE?: number
}

@Injectable({
	providedIn: 'root'
})
export class UserDataService {
	private user: User;

	static USER_TYPES: IUSERTYPES = {
		ADMIN: 1,
		BACKOFFICE: 4
	};

	static USER_REGION_ACCESS: IUSERREGIONACCESS = {
		REGIONAL: 1,
		DISTRICT: 2,
		COUNTY: 3
	}

	constructor(
		private storageService: StorageService,
		private WebService: WebService) {
	}

	public GetData(): Promise<User> {
		return new Promise((resolve, reject) => {
			if (this.user) {
				return resolve(this.user);
			}

			this.WebService.GetUserData().then(result => {
				if (result.Status == WebService.RESULT_OPERATION.SUCCESS) {
					this.user = new User(result.Object);

					resolve(this.user);
				}
				else {
					reject(result.Error);
				}
			})
				.catch(error => {
					// console.log("UserDataService : ", error);
				});
		});
	}

	public SetUserData(data: User = null) {
		this.user = data;
	}
}

