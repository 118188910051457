export interface IImagePhoto
{
	PhysicalName?: string;
	OriginalName?: string;
	ImageBase64?: string;
	ImagePath?: string;
}

export class ImagePhoto implements IImagePhoto
{
	/**
	 * O nome fisico do nome do ficheiro.
	 */
	public PhysicalName: string;

	/**
	 * Nome original do ficheiro (upload).
	 */
	public OriginalName: string;

	/**
	 * String base64 da imagem.
	 */
	public ImageBase64: string;

	/**
	 * Caminho da imagem no servidor.
	 */
	public ImagePath: string;

	constructor(data: Partial<ImagePhoto> = {})
	{
		data = data || {};
		
		this.PhysicalName 	= data.PhysicalName || "";
		this.OriginalName 	= data.OriginalName || "";
		this.ImageBase64 	= data.ImageBase64 	|| "";
		this.ImagePath 		= data.ImagePath 	|| "";
	}
}