import { environment } from './../../../environments/environment.prod';

// import { ElectronUpdaterService } from './../../services/electron-updater.service';
import { AppUpdaterPage } from './../../pages/app-updater/app-updater.page';
import { PAGES } from './../../pages/Index';
import { SessionService } from './../../services/session.service';
import { Component, OnInit } from '@angular/core';
import { NavController, ModalController } from '@ionic/angular';
@Component(
	{
		selector: 'sidemenu-footer',
		templateUrl: './footer.component.html',
		styleUrls: ['./footer.component.scss']
	})
export class FooterComponent implements OnInit {
	public IsElectron: boolean;

	public AppVersion: string;
	public AppVersionWeb: string;

	constructor(private SessionService: SessionService,
		private NavController: NavController,
		private ModalController: ModalController,
		// private ElectronUpdaterService: ElectronUpdaterService
		) {

	}

	ngOnInit() {
		// this.AppVersion = this.ElectronUpdaterService.GetAppVersion();

		// this.IsElectron = this.ElectronUpdaterService.IsElectron();

		this.AppVersionWeb = environment.ProjectConfig.Version;
	}

	OnCheckUpdates(e: any) {
		this.ShowUpdatePage();
	}

	/**
	 * Invoca modal com janela de verificação manual de updates.
	 * @param update 
	 */
	async ShowUpdatePage(update: any = null) {
		const modal = await this.ModalController.create(
			{
				component: AppUpdaterPage,
				cssClass: "modal-extra-small",
				backdropDismiss: false
			});

		return await modal.present();
	}

	OnLogout(ev: any) {
		this.SessionService.Logout();

		const t = setTimeout(() => {
			clearTimeout(t);
			this.NavController.navigateRoot([PAGES.LOGIN.path], { animated: true, animationDirection: 'back' });
		}, 800);
	}
}
