import { Directive, Input, ElementRef, OnInit } from '@angular/core';

@Directive({
	selector: '[status-color]'
})
export class StatusColorDirective implements OnInit {
	@Input('status-color') Id: number;
	private El: ElementRef;
	constructor(el: ElementRef) {
		this.El = el;

	}
	ngOnInit() {
		switch (this.Id) {
			case 16:
				this.El.nativeElement.style.color = "var(--ion-color-success)";
				break;
			case 2:
			case 3:
			case 5:
			case 7:
			case 8:
			case 10:
			case 11:
			case 13:
			case 14:
				this.El.nativeElement.style.color = "var(--ion-color-warning)";
				break;
			case 0:
			case 4:
			case 6:
			case 9:
			case 12:
			case 15:
				this.El.nativeElement.style.color = "var(--ion-color-danger)";
			case 1:
				this.El.nativeElement.style.color = "var(--ion-color-tertiary)";
			default:
				this.El.nativeElement.style.color = "var(--ion-color-medium)";
				break;
		}
	}


}
