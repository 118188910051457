import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';

import { WebService } from './web.service';
import { UtilsService } from './utils.service';
import { ItemMenuModel } from '../models/ItemMenuModel';
import { PermissionActions, IPermissionActions } from '../models/Permission';

@Injectable()

export class MenuDataService {
	public Data: Array<ItemMenuModel>;
	public Actions: IPermissionActions;

	constructor(private UtilsService: UtilsService,
		private WebService: WebService) {
		this.Data = [];
		this.Actions = new PermissionActions();
	}

	/**
	 * Efectua chamada ao webservice para pedir estrutura de menus.
	 * Se a chamada já tiver sido efectuada retorna o resultado em memoria.
	 */
	public GetData(): Promise<Array<ItemMenuModel>> {
		// return new Promise((resolve, reject) => 
		// {
		// if (this.Data && this.Data.length > 0) 
		// {
		// 	resolve(this.Data);
		// 	return;
		// }

		return this.WebService.GetMenuData().then(result => {
			// this.Data = [];

			// result.Object.forEach((element, index) => 
			// {
			// 	let item = new ItemMenuModel(element);

			// 	const [first, second] = item.Icon.split('icon-');

			// 	if (first == "" || second != undefined) item.Icon = second;

			// 	item.Index = index;

			// 	if (item.Id != 0)
			// 		this.Data.push(item);
			// });

			this.Data.length = 0;
			this.Actions = new PermissionActions();

			if (result.Status === WebService.RESULT_OPERATION.SUCCESS) {

				if (result.Object) {

					if (result.Object.Menus && result.Object.Menus.length > 0) {
						result.Object.Menus.forEach(element => {
							// [PREGO] depois remover condição
							// if (element.Id != 32)
							this.Data.push(new ItemMenuModel(element));
						});

						// [PREGO] Adicionar menu estatico
						// if (!environment.production) {
						// 	this.Data.push(new ItemMenuModel(
						// 		{
						// 			Actions: [
						// 				{
						// 					Description: "ACCESS_DETAILS",
						// 					Feature: "ACCESS_DETAILS",
						// 					Id: 1134,
						// 					IdPermission: 0,
						// 					IdTarget: 0,
						// 					idSubMenu: 8
						// 				},
						// 				{
						// 					Description: "ACCESS_DETAILS",
						// 					Feature: "ACCESS_DETAILS",
						// 					Id: 1135,
						// 					IdPermission: 0,
						// 					IdTarget: 0,
						// 					idSubMenu: 8
						// 				},
						// 				{
						// 					Description: "ACCESS_DETAILS",
						// 					Feature: "ACCESS_DETAILS",
						// 					Id: 1136,
						// 					IdPermission: 0,
						// 					IdTarget: 0,
						// 					idSubMenu: 8
						// 				},
						// 				{
						// 					Description: "ACCESS_DETAILS",
						// 					Feature: "ACCESS_DETAILS",
						// 					Id: 1137,
						// 					IdPermission: 0,
						// 					IdTarget: 0,
						// 					idSubMenu: 8
						// 				},
						// 				{
						// 					Description: "ACCESS_DETAILS",
						// 					Feature: "ACCESS_DETAILS",
						// 					Id: 1138,
						// 					IdPermission: 0,
						// 					IdTarget: 0,
						// 					idSubMenu: 8
						// 				},
						// 				{
						// 					Description: "ACCESS_DETAILS",
						// 					Feature: "ACCESS_DETAILS",
						// 					Id: 1139,
						// 					IdPermission: 0,
						// 					IdTarget: 0,
						// 					idSubMenu: 8
						// 				}
						// 			],
						// 			Default: false,
						// 			Description: "CANDIDATURES_LIST",
						// 			Icon: "quote",
						// 			Id: 99,
						// 			IdComponent: "/candidatures/list",
						// 			IdParent: 0,
						// 			IsNotActive: false,
						// 			Name: "CANDIDATURES_LIST",
						// 			Order: 1,
						// 			ResourceName: "CANDIDATURES_LIST",
						// 			Resources: [],
						// 			SubMenus: [

						// 			]
						// 		}
						// 	));
						// }
					}
					if (result.Object.Actions)
						this.Actions = new PermissionActions(result.Object.Actions);
				}

			}

			//INVOCA FUNCAO RECURSIVA PARA ITERAR SOBRE ESTRUTURA DE MENU
			//E ADICIONAR POSSIVEIS SOURCES EM FALTA NA APLICACAO
			//this.addMissingResourcesFromMenuData(this.Data);

			console.log(this.Data);

			return this.Data;
		});

		// });
	}

	/**
	 * Pesquisa recursivamente num Array<ItemMenuModel> e retorna objecto ItemMenuModel se existir um parent do item.
	 * Se um item não tiver parent retorna null apesar do item existir.
	 * @param id number Identificador do menu
	 * @param data Array<ItemMenuModel> Array de ItemMenuModel
	 */
	GetParentMenu(id: number, data: Array<ItemMenuModel> = this.Data): ItemMenuModel {
		let child: ItemMenuModel = this.GetMenu(id);

		if (child && child.Parent)
			return child.Parent;

		return null;
	}

	VerifyMenuAccess(id: number) {
		return false;
	}
	/**
	 * Pesquisa recursivamente num Array<ItemMenuModel> e retorna objecto ItemMenuModel.
	 * Se não encontrar match retorna null.
	 * @param id number Identificador do menu
	 * @param data Array<ItemMenuModel> Array de ItemMenuModel
	 */
	GetMenu(id: number, data: Array<ItemMenuModel> = this.Data): ItemMenuModel {
		for (let index = 0; index < data.length; index++) {
			const element = data[index];

			if (element.Id == id) return element;

			if (element.SubMenus && element.SubMenus.length > 0) {
				const submenu = this.GetMenu(id, element.SubMenus);

				if (submenu) return submenu;
			}
		}
	}

	/**
	 * 
	 * @param path 
	 * @param action nome da permissao a verificar no menu nas ACTIONS
	 * @param data 
	 */
	GetMenuByPath(path: string, action: string = null, data: Array<ItemMenuModel> = this.Data): ItemMenuModel {
		// console.log(path);

		for (let index = 0; index < data.length; index++) {
			const element = data[index];

			if (element.IdComponent === path) {
				if (action) { // verificar permissões no menu
					const e = element.Actions.filter(item => item.Feature === action);
					// console.log(e);
					return e && e.length > 0 ? element : undefined;
				}

				return element;
			}

			if (element.SubMenus && element.SubMenus.length > 0) {
				const submenu = this.GetMenuByPath(path, action, element.SubMenus);

				if (submenu) return submenu;
			}
		}
	}

	/**
	 * Adiciona recursivamente entradas de resources existentes no array de objectos de menus.
	 * @param menu Array<ItemMenuModel>
	 */
	private addMissingResourcesFromMenuData(menu: Array<ItemMenuModel>) {
		menu.forEach(item => {

			let res = {};

			res[item.ResourceName] = item.Description;

			this.UtilsService.AddResources('pt-pt', res);

			// item.Resources.forEach(resource => {
			// 	res = {};

			// 	// {
			// 	// 	"Default": false,
			// 	// 	"Description": "",
			// 	// 	"Icon": "icon-home",
			// 	// 	"Id": 1,
			// 	// 	"IdComponent": "/dashboard",
			// 	// 	"IdParent": null,
			// 	// 	"IsNotActive": false,
			// 	// 	"Name": "DASHBOARD",
			// 	// 	"Order": 1,
			// 	// 	"ResourceName": "DASHBOARD",
			// 	// 	"Resources": 
			// 	// 	[
			// 	// 		{
			// 	// 			"Descricao": "Dashboard",
			// 	// 			"Id": 0,
			// 	// 			"IdTipo": 1,
			// 	// 			"Ordem": 0,
			// 	// 			"Resource": "DASHBOARD",
			// 	// 			"Valor": null
			// 	// 		}
			// 	// 	],
			// 	// 	"SubMenus": []
			// 	// }

			// 	res[resource.Resource] = resource.Descricao;

			// 	//ADICIONA OPCAO DE RESOURCE AS OPCOES JA EXISTENTES EM JSON
			// 	switch (resource.IdTipo) {
			// 		//1 - Portugues, 2 - Ingles, 3 - Frances, 4 - Espanhol
			// 		case 1: this.UtilsService.AddResources('pt-pt', res); break;
			// 		case 2: this.UtilsService.AddResources('en', res); break;
			// 		case 3: this.UtilsService.AddResources('fr', res); break;
			// 		case 4: this.UtilsService.AddResources('es-eu', res); break;
			// 		default: break;
			// 	}
			// });

			if (item.SubMenus && item.SubMenus.length > 0) {
				this.addMissingResourcesFromMenuData(item.SubMenus);
			}
		});
	}
}
