export class SerializerBaseClass
{
	Serialize(data: any)
	{
		if (data)
			(typeof data == "string") ? this.SerializeFromJSONString(data) : this.SerializeFromJSONObject(data);
	}

	SerializeFromJSONObject(json: Object)
	{
		for (var propName in json) 
		{
			this[propName] = json[propName];
		}
	}

	SerializeFromJSONString(json: string) 
	{
		var jsonObj = JSON.parse(json);

		this.SerializeFromJSONObject(jsonObj);
	}

	ToJSONString(): string 
	{
		let json = JSON.stringify(this);

		Object.keys(this).filter(key => key[0] === "_").forEach(key =>
		{
			json = json.replace(key, key.substring(1));
		});

		return json;
	}
}