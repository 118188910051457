import { IKeyValuePair } from './../../models/KeyValuePair';
// import { ElectronUpdaterService, IStatusUpdate } from './../../services/electron-updater.service';
// import { ElectronService } from 'ngx-electron';
import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';

// import { ModalController } from '@ionic/angular';

@Component(
	{
		selector: 'app-updater',
		templateUrl: './app-updater.page.html',
		styleUrls: ['./app-updater.page.scss'],
	})
export class AppUpdaterPage implements OnInit, OnDestroy {
	// private Updater: any;

	public MetadataNewUpdate: any;

	public CurrentStatus: IKeyValuePair;

	public AllStatus: any;//IStatusUpdate;

	// public AppVersion: string;

	// constructor(private ElectronUpdaterService: ElectronUpdaterService,
	// 	private ElectronService: ElectronService,
	// 	private ChangeDetectorRef: ChangeDetectorRef,
	// 	private ModalController: ModalController) {
	// 	this.AppVersion = this.ElectronUpdaterService.GetAppVersion();

	// 	this.AllStatus = this.ElectronUpdaterService.AllStatus;

	// 	this.MetadataNewUpdate = null;
	// }

	ngOnInit() {

	// 	if (!this.ElectronService.isElectronApp) {
	// 		this.setStatus(this.AllStatus.FeatureNotAvailable);

	// 		return;
		}

	// 	//ACTUALIZA VALOR DA VERSAO
	// 	this.setStatus(this.AllStatus.Default);

	// 	//GET DA REFERENCIA DO UPDATER
	// 	this.Updater = this.ElectronUpdaterService.GetUpdater();

	// 	if (this.Updater) {
	// 		//ADICIONA TODOS OS EVENTOS DE UPDATE PARA TRACKING/LOG
	// 		this.Updater.on('update-not-available', this.updateNotAvailable);
	// 		this.Updater.on('update-available', this.updateAvailable);
	// 		this.Updater.on('update-downloading', this.updateDownloading);
	// 		this.Updater.on('update-downloaded', this.updateDownloaded);
	// 		this.Updater.on('error', this.updaterError);
	// 	}

	// 	//AO PESQUISAR UPDATE UM DOS EVENTOS ADICIONADOS AO UPDATER IRA DISPARAR
	// 	this.searchUpdate();
	// }

	ngOnDestroy() {
	// 	if (this.Updater) {
	// 		this.Updater.off('update-not-available', this.updateNotAvailable);
	// 		this.Updater.off('update-available', this.updateAvailable);
	// 		this.Updater.off('update-downloading', this.updateDownloading);
	// 		this.Updater.off('update-downloaded', this.updateDownloaded);
	// 		this.Updater.off('error', this.updaterError);
	// 	}
	}

	// private updateNotAvailable = () => {
	// 	this.setStatus(this.AllStatus.UpdateNotAvailable);
	// }

	// private updateAvailable = (meta) => {
	// 	//SET RAMDOM DELAY PARA O USER TER PERCEPCAO DO QUE ACONTECEU
	// 	const t = setTimeout(() => {
	// 		clearTimeout(t);
	// 		this.MetadataNewUpdate = meta;

	// 		this.setStatus(this.AllStatus.UpdateAvailable);

	// 	}, 1000 + (~~(Math.random() * 1000) + 0));
	// }

	// private updateDownloading = (meta) => {
	// 	this.setStatus(this.AllStatus.DownloadingUpdate);
	// }

	// private updateDownloaded = (meta) => {
	// 	this.setStatus(this.AllStatus.DownloadComplete);

	// 	//ADD DELAY PARA O USER TER PERCECAO DO PROXIMO PASSO
	// 	//VAI RENICIAR UMA VEZ QUE O UPDATE FOI DESCARREGADO
	// 	const t = setTimeout(() => {
	// 		clearTimeout(t);
	// 		this.Updater.quitAndInstall();
	// 	}, 2000);
	// }

	// private updaterError = (error) => {
	// 	this.setStatus(this.AllStatus.Error);
	// }

	// private searchUpdate() {
	// 	if (this.ElectronService.isElectronApp) {
	// 		this.setStatus(this.AllStatus.SearchingUpdate);

	// 		this.Updater.checkForUpdates();
	// 	}
	// 	else {
	// 		this.setStatus(this.AllStatus.FeatureNotAvailable);
	// 	}
	// }

	public OnDownloadUpdateAndRestart(e) {
	// 	if (!this.ElectronService.isElectronApp) return;

	// 	//INICIA DOWNLOAD, QUANDO O DOWNLOAD TERMINAR
	// 	//DISPARA EVENTO update-downloaded ASSOCIADO AO this.Updater
	// 	this.Updater.downloadUpdate();
	// }

	// private setStatus(status: IKeyValuePair) {
	// 	const t = setTimeout(() => {
	// 		clearTimeout(t);
	// 		this.CurrentStatus = status;

	// 		this.ChangeDetectorRef.detectChanges();
	// 	}, 0);
	}

	public OnClose(e) {
	// 	this.ModalController.dismiss({ update: false });
	}
}
